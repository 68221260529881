<script>
import appConfig from "../../../app.config";
import NavMenu from "../landing/inc/nav-menu.vue";
import Footer from "../landing/inc/footer.vue";

import Img1 from "@/assets/images/demos/default.png";
import Img2 from "@/assets/images/demos/saas.png";
import Img3 from "@/assets/images/demos/material.png";
import Img4 from "@/assets/images/demos/minimal.png";
import Img5 from "@/assets/images/demos/creative.png";
import Img6 from "@/assets/images/demos/modern.png";
import Img7 from "@/assets/images/demos/interactive.png";

export default {
    page: {
        title: "Starter",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Starter",
            Img1, Img2, Img3, Img4, Img5, Img6, Img7,
            items: [{
                text: "Pages",
                href: "/",
            },
            {
                text: "Starter",
                active: true,
            },
            ],
        };
    },
    components: {
        NavMenu,
        Footer
    },
};
</script>

<template>
    <div class="layout-wrapper">

        <nav-menu></nav-menu>

        <div class="bg-overlay bg-overlay-pattern"></div>

        <section class="section header">
            <div class="bg-overlay"></div>
        </section>

        <section class="section pb-0 hero-section" id="hero" v-if="false">

            <div class="bg-overlay bg-overlay-pattern"></div>
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8" sm="10">
                        <div class="text-center mt-lg-5 pt-5">
                            <h1 class="display-6 fw-semibold mb-3 lh-base">다온
                                <span class="text-success">ERP</span>
                            </h1>
                            <p class="lead text-muted lh-base">아직도 한두개 메뉴를 사용하는 ERP 솔류션을 사용하고 계신가요? 자사 실정에 맞는 ERP 를 맞춤으로 제작하세요.</p>

                            <div class="d-flex gap-2 justify-content-center mt-4">
                                <router-link href="/pages/contactus" class="btn btn-primary" v-if="false">데모요청 <i
                                        class="ri-arrow-right-line align-middle ms-1"></i></router-link>
                                <router-link to="/pages/contactus" class="btn btn-danger">개발문의 <i
                                        class="ri-eye-line align-middle ms-1"></i></router-link>
                            </div>
                        </div>

                        <div class="mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel">
                            <div class="demo-img-patten-top d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>
                            <div class="demo-img-patten-bottom d-none d-sm-block">
                                <img src="@/assets/images/landing/img-pattern.png" class="d-block img-fluid" alt="...">
                            </div>

                            <b-carousel :interval="2000" class="carousel slide carousel-fade">
                                <div class="carousel-inner shadow-lg p-2 bg-white rounded">
                                    <b-carousel-slide active :img-src="Img1" />
                                    <b-carousel-slide :img-src="Img2" />
                                    <b-carousel-slide :img-src="Img3" />
                                    <b-carousel-slide :img-src="Img4" />
                                    <b-carousel-slide :img-src="Img5" />
                                    <b-carousel-slide :img-src="Img6" />
                                    <b-carousel-slide :img-src="Img7" />
                                </div>
                            </b-carousel>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <div class="position-absolute start-0 end-0 bottom-0 hero-shape-svg">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <g mask="url(&quot;#SvgjsMask1003&quot;)" fill="none">
                        <path d="M 0,118 C 288,98.6 1152,40.4 1440,21L1440 140L0 140z">
                        </path>
                    </g>
                </svg>
            </div>
        </section>
        
        <section class="section" id="services">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h1 class="mb-3 ff-secondary fw-semibold lh-base">인쇄 회사에 최적화된 <span class="text-success">ERP 맞춤개발</span></h1>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="g-3">
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-building-3-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">인쇄회사 맞춤형 ERP</h5>
                                <p class="text-muted my-3 ff-secondary">인쇄회사에 필요한 기능을 분석하여 최적화된 시스템을 맞춤형으로 개발해드립니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-database-2-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">정확한 업무기록</h5>
                                <p class="text-muted my-3 ff-secondary">회사에 진행되는 업무를 DB화 하여 업무의 정확성을 높일 수 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-line-chart-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">데이터에 기반한 의사결정</h5>
                                <p class="text-muted my-3 ff-secondary">전산화된 업무데이터를 기반으로 하여 합리적인 정확한 판단을 하실 수 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-exchange-dollar-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">합리적인 가격</h5>
                                <p class="text-muted my-3 ff-secondary">합리적인 가격으로 개발해드립니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-chrome-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">웹기반 ERP</h5>
                                <p class="text-muted my-3 ff-secondary">100% 웹기반으로 시간과 장소에 제약없이 접속하여 회사업무가 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-exchange-fill fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">업무효율화</h5>
                                <p class="text-muted my-3 ff-secondary">정해진 절차를 업무를 시스템화여 업무 효율성을 높이실 수 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-settings-5-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">업무자동화</h5>
                                <p class="text-muted my-3 ff-secondary">매일 반복되는 업무를 자동화하여 업무 생산성을 향상시킬 수 있습니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-stack-line fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">모바일최적화</h5>
                                <p class="text-muted my-3 ff-secondary">Bootstrap 을 적용한 반응형시스템으로 모바일 및 다양한 디바이스에서 접속이 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="4">
                        <div class="d-flex p-3">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm icon-effect">
                                    <div class="avatar-title bg-transparent text-success rounded-circle">
                                        <i class="ri-google-fill fs-36"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h5 class="fs-18">REST API 방식의 개발환경</h5>
                                <p class="text-muted my-3 ff-secondary">REST API 개발표준에 준수한 개발로 유연한 통합개발이 가능합니다.</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        
        <section class="section bg-light" id="marketplace" v-if="false">
            <b-container>
                <b-row class="justify-content-center">
                    <b-col lg="8">
                        <div class="text-center mb-5">
                            <h2 class="mb-3 fw-semibold lh-base">다온 ERP 특징</h2>
                            <p class="text-muted mb-4">최적화된 ERP Layout</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="4" class="product-item artwork crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature1.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 19.29k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Creative Filtered
                                        Portrait</router-link>
                                </h5>
                                <p class="text-muted mb-0">Photography</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">75.3ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">67.36 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item music crypto-card games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature2.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 23.63k </p>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">The Chirstoper</router-link>
                                </h5>
                                <p class="text-muted mb-0">Music</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">412.30ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">394.7 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music games">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature3.png" alt=""
                                    class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 15.93k </p>
                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Evolved Reality</router-link>
                                </h5>
                                <p class="text-muted mb-0">Video</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">2.75ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">3.167 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item crypto-card 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature4.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 14.85k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Abstract Face Painting</router-link>
                                </h5>
                                <p class="text-muted mb-0">Collectibles</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">122.34ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">97.8 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item games music 3d-style">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature5.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 64.10k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Long-tailed Macaque</router-link>
                                </h5>
                                <p class="text-muted mb-0">Artwork</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">874.01ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">745.14 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature6.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 36.42k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Robotic Body Art</router-link>
                                </h5>
                                <p class="text-muted mb-0">Artwork</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">41.658 ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">34.81 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature7.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 36.42k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Robotic Body Art</router-link>
                                </h5>
                                <p class="text-muted mb-0">Artwork</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">41.658 ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">34.81 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature8.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 36.42k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Robotic Body Art</router-link>
                                </h5>
                                <p class="text-muted mb-0">Artwork</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">41.658 ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">34.81 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                    <b-col lg="4" class="product-item artwork music crypto-card">
                        <b-card no-body class="explore-box card-animate">
                            <div class="bookmark-icon position-absolute top-0 end-0 p-2">
                                <button type="button" class="btn btn-icon active" data-bs-toggle="button"
                                    aria-pressed="true"><i class="mdi mdi-cards-heart fs-16"></i></button>
                            </div>
                            <div class="explore-place-bid-img">
                                <img src="@/assets/images/erp/erp-feature9.png" alt="" class="card-img-top explore-img" />
                                <div class="bg-overlay"></div>
                                <div class="place-bid-btn">
                                    <b-link href="#!" class="btn btn-success"><i
                                            class="ri-auction-fill align-bottom me-1"></i> Place Bid</b-link>
                                </div>
                            </div>
                            <b-card-body>
                                <p class="fw-medium mb-0 float-end"><i
                                        class="mdi mdi-heart text-danger align-middle"></i> 36.42k </p>

                                <h5 class="mb-1">
                                    <router-link to="/apps/nft-item-detail">Robotic Body Art</router-link>
                                </h5>
                                <p class="text-muted mb-0">Artwork</p>
                            </b-card-body>
                            <b-card-footer class="border-top border-top-dashed">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1 fs-14">
                                        <i class="ri-price-tag-3-fill text-warning align-bottom me-1"></i> Highest:
                                        <span class="fw-medium">41.658 ETH</span>
                                    </div>
                                    <h5 class="flex-shrink-0 fs-14 text-primary mb-0">34.81 ETH</h5>
                                </div>
                            </b-card-footer>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="py-5 position-relative" v-if="false">
            <b-container>
                <b-row class="justify-content-center mt-5">
                <b-col lg="4">
                    <div class="text-center mb-4 pb-2">
                    <h4 class="fw-semibold fs-22">ERP 도입 이전과 도입 후 비교</h4>
                    <p class="text-muted mb-4 fs-15">
                        Simple pricing. No hidden fees. Advanced features for you business.
                    </p>
                    </div>
                </b-col>
                </b-row>

                <b-row>
                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-default text-success">도입전</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                    <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        수작업 실적집계로 인한 <span class="text-success fw-semibold">시스템 등록 지연</span>
                                    </li>
                                    <li>
                                        작업자에 의한 수집오류
                                    </li>
                                    <li>
                                        공정간 재고량과 이동 시 LOSS 파악이 안됨
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                    <b-col lg="2">
                        <div class="text-center"><span class="mdi mdi-arrow-right-bold" style="font-size:60px;"></span></div>
                    </b-col>

                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-primary">도입후</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        물류 이동 단위에 의한 실시간 생산실적정보 수집
                                    </li>
                                    <li>
                                        공정 입출고 정보를 실시간 관리
                                    </li>
                                    <li>
                                        제공의 투명한 파악
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                </b-row>

                <b-row>
                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-default text-success">도입전</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                    <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        수작업 실적집계로 인한 <span class="text-success fw-semibold">시스템 등록 지연</span>
                                    </li>
                                    <li>
                                        작업자에 의한 수집오류
                                    </li>
                                    <li>
                                        공정간 재고량과 이동 시 LOSS 파악이 안됨
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                    <b-col lg="2">
                        <div class="text-center"><span class="mdi mdi-arrow-right-bold" style="font-size:60px;"></span></div>
                    </b-col>

                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-primary">도입후</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        물류 이동 단위에 의한 실시간 생산실적정보 수집
                                    </li>
                                    <li>
                                        공정 입출고 정보를 실시간 관리
                                    </li>
                                    <li>
                                        제공의 투명한 파악
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                </b-row>

                <b-row>
                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-default text-success">도입전</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                    <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        수작업 실적집계로 인한 <span class="text-success fw-semibold">시스템 등록 지연</span>
                                    </li>
                                    <li>
                                        작업자에 의한 수집오류
                                    </li>
                                    <li>
                                        공정간 재고량과 이동 시 LOSS 파악이 안됨
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                    <b-col lg="2">
                        <div class="text-center"><span class="mdi mdi-arrow-right-bold" style="font-size:60px;"></span></div>
                    </b-col>

                    <b-col lg="5">
                        <b-card no-body class="pricing-box ribbon-box ribbon-fill text-center">
                            <div class="ribbon ribbon-primary">도입후</div>

                            <b-card-body class="border-start mt-4 mt-lg-0">
                                <b-card-header class="bg-light">
                                <h5 class="fs-15 mb-0">생산진도의 단계별 파악 어려움</h5>
                                </b-card-header>
                                <b-card-body class="pb-0">
                                <ul class="list-unstyled vstack gap-3 mb-0">
                                    <li>
                                        물류 이동 단위에 의한 실시간 생산실적정보 수집
                                    </li>
                                    <li>
                                        공정 입출고 정보를 실시간 관리
                                    </li>
                                    <li>
                                        제공의 투명한 파악
                                    </li>
                                </ul>
                                </b-card-body>
                            </b-card-body>

                        </b-card>
                    </b-col>

                </b-row>
                
            </b-container>
        </section>

        <section class="py-5 position-relative" v-if="false">

            <b-container>
                <b-row>
                    <b-col lg="12">
                        <b-card no-body>
                            <b-card-header>
                                <b-card-title class="mb-0">DAON ERP</b-card-title>
                            </b-card-header>
                            <b-card-body>
                                <div class="sitemap-content">
                                    <figure class="sitemap-horizontal">
                                        <ul class="administration">
                                            <li>
                                                <ul class="director">
                                                    <li>
                                                        <b-link href="javascript:void(0);" class="fw-semibold"><span>DAON ERP</span></b-link>
                                                        <ul class="subdirector">
                                                            <li><b-link href="javascript:void(0);" class="fw-semibold"><span>Contact Us</span></b-link></li>
                                                        </ul>
                                                        <ul class="departments">
                                                            <li><b-link href="javascript:void(0);" class="fw-semibold"><span>Main Pages</span></b-link></li>

                                                            <li class="department">
                                                                <b-link href="javascript:void(0);" class="fw-semibold"><span>영업관리</span></b-link>
                                                                <ul>
                                                                    <li><b-link href="javascript:void(0);"><span>Sign Up</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Login</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Profile
                                                                                Settings</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Modify
                                                                                Reservation</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Cancel
                                                                                Reservation</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Write
                                                                                Reviews</span></b-link></li>
                                                                </ul>
                                                            </li>
                                                            <li class="department">
                                                                <b-link href="javascript:void(0);" class="fw-semibold"><span>구매·자재관리</span></b-link>
                                                                <ul>
                                                                    <li><b-link href="javascript:void(0);"><span>Overview</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Connect Via Social
                                                                                Media</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Careers</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Team
                                                                                Members</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Policies</span></b-link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li class="department">
                                                                <b-link href="javascript:void(0);" class="fw-semibold"><span>인사·급여관리</span></b-link>
                                                                <ul>
                                                                    <li><b-link href="javascript:void(0);"><span>Travel
                                                                                Details</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Reservation
                                                                                Process</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Payment
                                                                                Option</span></b-link></li>
                                                                    <li><b-link
                                                                            href="javascript:void(0);"><span>Comfirmation</span></b-link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li class="department">
                                                                <b-link href="javascript:void(0);"
                                                                    class="fw-semibold"><span>회계관리</span></b-link>
                                                                <ul>
                                                                    <li><b-link
                                                                            href="javascript:void(0);"><span>Architecture</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Art</span></b-link></li>
                                                                    <li><b-link
                                                                            href="javascript:void(0);"><span>Entertainment</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>History</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Science</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Sports</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Music</span></b-link>
                                                                    </li>
                                                                    <li><b-link href="javascript:void(0);"><span>Tracking
                                                                                Camp</span></b-link></li>
                                                                </ul>
                                                            </li>
                                                            <li class="department">
                                                                <b-link href="javascript:void(0);" class="fw-semibold"><span>업무관리</span></b-link>
                                                                <ul>
                                                                    <li><b-link href="javascript:void(0);"><span>General
                                                                                Travel</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Helpth
                                                                                Concerns</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>Safety
                                                                                Measures</span></b-link></li>
                                                                    <li><b-link href="javascript:void(0);"><span>FAQ's</span></b-link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </figure>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="py-5 bg-primary position-relative">
            <div class="bg-overlay bg-overlay-pattern opacity-50"></div>
            <b-container>
                <b-row class="align-items-center gy-4">
                    <b-col sm>
                        <div>
                            <h4 class="text-white mb-0 fw-semibold">우리 회사의 업무를 온라인으로 바꾸고 싶다면 언제든지 문의주세요. 편하게 상담해드립니다.</h4>
                        </div>
                    </b-col>

                    <b-col sm="auto">
                        <div>
                            <router-link to="/pages/contactus" class="btn bg-gradient btn-danger">인쇄 ERP 개발문의</router-link>
                        </div>
                    </b-col>

                </b-row>

            </b-container>

        </section>
        <Footer></Footer>
    </div>
</template>